import { Form, Formik } from "formik";
import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";
import { InputNumber } from "primereact/inputnumber";
import { InputText } from "primereact/inputtext";
import { classNames } from "primereact/utils";
import * as Yup from 'yup';
import PlansState from "../Context/state";


const ModalEditPlan = ({ planDialog, plan, hideDialog }) => {
    const { EditPlans } = PlansState()

    const UserSchema = Yup.object().shape({
        name: Yup.string()
          .min(2, 'Muito Curto!')
          .max(50, 'Muito Longo!')
          .required('Campo Obrigatório'),
        price: Yup.number().required('Campo Obrigatório'),
        numberZones: Yup.number().required('Campo Obrigatório'),
        maxSize: Yup.number().required('Campo Obrigatório'),
      });


    return (
        <Dialog visible={planDialog} style={{ width: '450px' }} header="Detalhes do plano" modal className="p-fluid" onHide={hideDialog}>
            <Formik validationSchema={UserSchema} initialValues={{ planId: plan?.id, name: plan?.name, price: plan?.price || 0, numberZones: plan?.numberZones || 0, maxSize: plan?.maxSize || 0 }} onSubmit={(values) => {
                EditPlans(values);
                hideDialog()
            }}>
                {({ values, handleChange, errors, touched, handleSubmit }) => {
                    return (
                        <Form onSubmit={handleSubmit}>
                            <div className="field">
                                <label htmlFor="name">Name</label>
                                <InputText name="name" id="name" value={values?.name} onChange={handleChange} required autoFocus className={classNames({ 'p-invalid': errors.name && touched.name })} />
                                {errors.name && touched.name && <small className="p-invalid">{errors.name}</small>}
                            </div>
                            <div className="field">
                                <label htmlFor="name">Preço</label>
                                <InputNumber mode="currency" currency="BRL" locale="pt-BR" name="price" id="price" value={values?.price} onValueChange={handleChange} required autoFocus className={classNames({ 'p-invalid': errors.price && touched.price })} />
                                {errors.price && touched.price && <small className="p-invalid">{errors.price}</small>}
                            </div>
                            <div className="field">
                                <label htmlFor="name">Nº de Zonas</label>
                                <InputNumber showButtons name="numberZones" id="numberZones" value={values?.numberZones} onValueChange={handleChange} required autoFocus className={classNames({ 'p-invalid': errors.numberZones && touched.numberZones })} />
                                {errors.numberZones && touched.numberZones && <small className="p-invalid">{errors.numberZones}</small>}
                            </div>
                            <div className="field">
                                <label htmlFor="name">Tamanho máximo</label>
                                <InputNumber showButtons name="maxSize" id="maxSize" value={values?.maxSize} onValueChange={handleChange} required autoFocus className={classNames({ 'p-invalid': errors.maxSize && touched.maxSize })} />
                                {errors.maxSize && touched.maxSize && <small className="p-invalid">{errors.maxSize}</small>}
                            </div>
                            <div className="p-5"></div>
                            <div className="flex align-items-center justify-content-end">
                                <div className=" w-6 flex align-items-center justify-content-end">
                                    <Button label="Cancel" type="button" icon="pi pi-times" className="p-button-text" onClick={hideDialog} />
                                    <Button label="Save" type="submit" icon="pi pi-check" className="p-button-text" />
                                </div>
                            </div>
                        </Form>
                    )
                }}
            </Formik>
        </Dialog>
    )
}

export default ModalEditPlan