import axios from "axios";
import { getToken, isAuthenticated } from "../localStorage/localStorage";
const http = axios.create({
  baseURL: "https://devback.ludz.one/",
});

http.interceptors.request.use(async config => {
  const token = getToken();

  if (token && isAuthenticated()) {
    config.headers.Authorization = `Bearer ${token}`;
  }
  return config;
});

export default http;