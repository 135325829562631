import queryClient from "../../../service/reactQuery/queryClient";
import { useFetchPlansAll } from "../Service/query";
import { CreatePlanRequest, DeletePlansRequest, EditPlansRequest } from "../Service/request";
import { useMutation } from 'react-query'

// import { EditUserRequest, SignUpRequest } from "../Services/request";


const PlansState = () => {

    const { data: plans, isLoading } = useFetchPlansAll()

    const EditPlansMutation = useMutation(
        (body) => EditPlansRequest(body),
        {
            onMutate: () => {
            },
            onError: (error) => {

            },
            onSuccess: (result, veriables, context) => {
                queryClient.refetchQueries("UsePlansAll")
            },
        }
    );

    const DeletePlansMutation = useMutation(
        (id) => DeletePlansRequest(id),
        {
            onMutate: () => {
            },
            onError: (error) => {

            },
            onSuccess: (result, veriables, context) => {
                queryClient.refetchQueries("UsePlansAll")
            },
        }
    );

    const CreatePlanMutation = useMutation((data) => CreatePlanRequest(data), {
        onMutate: () => {
        },
        onError: (error) => {
        },
        onSuccess: (result, variables, context) => {
            queryClient.refetchQueries("UsePlansAll")
        },
    });

    const CreatePlan = (body) => {
        CreatePlanMutation.mutate(body)
    }

    const EditPlans = (body) => {
        EditPlansMutation.mutate(body)
    }

    const DeletePlans = (id) => {
        console.log(id)
        DeletePlansMutation.mutate(id)
    }

    return {
        plans, isLoading, EditPlans, CreatePlan, DeletePlans
    }
}

export default PlansState