import { Button } from 'primereact/button';
import { Column } from 'primereact/column';
import { DataTable } from 'primereact/datatable';
import { Dialog } from 'primereact/dialog';
import { InputText } from 'primereact/inputtext';
import { Toast } from 'primereact/toast';
import { Toolbar } from 'primereact/toolbar';
import React, { useRef, useState } from 'react';
import PlansState from './Context/state';
import ModalCreatePlan from './ModalCreatePlan';
import ModalEditPlan from './ModalEditPlan';

const PlansScreens = () => {

    const { plans, DeletePlans } = PlansState()
    const [planDialog, setPlanDialog] = useState(false);
    const [createDialog, setCreateDialog] = useState(false);

    const [deleteProductDialog, setDeleteProductDialog] = useState(false);
    const [plan, setPlan] = useState()
    const [globalFilter, setGlobalFilter] = useState(null);
    const toast = useRef(null);
    const dt = useRef(null);


    const openNew = () => {
        // setProduct(emptyProduct);
        setCreateDialog(true);
    };


    const editProduct = (plan) => {
        setPlan({ ...plan });
        setPlanDialog(plan);
    };



    const leftToolbarTemplate = () => {
        return (
            <React.Fragment>
                <div className="my-2">
                    <Button label="Novo" icon="pi pi-plus" className="p-button-success mr-2" onClick={openNew} />
                    {/* <Button label="Delete" icon="pi pi-trash" className="p-button-danger" onClick={confirmDeleteSelected} disabled={!selectedProducts || !selectedProducts.length} /> */}
                </div>
            </React.Fragment>
        );
    };



    const codeBodyTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title">Code</span>
                {rowData.id}
            </>
        );
    };

    const nameBodyTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title">Name</span>
                {rowData.name}
            </>
        );
    };



    const priceBodyTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title">Preço</span>
                {rowData?.price}
            </>
        );
    };

    const maxSizeBodyTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title">Tamanho máximo</span>
                {rowData?.maxSize + "MB"}
            </>
        );
    };

    const zonasBodyTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title">Nº de zonas</span>
                {rowData.numberZones}
            </>
        );
    };

    const actionBodyTemplate = (rowData) => {
        return (
            <div className="actions">
                <Button icon="pi pi-pencil" className="p-button-rounded p-button-success mr-2" onClick={() => editProduct(rowData)} />
                <Button icon="pi pi-trash" className="p-button-rounded p-button-warning mt-2" onClick={() => setDeleteProductDialog(rowData)} />

            </div>
        );
    };

    const header = (
        <div className="flex flex-column md:flex-row md:justify-content-between md:align-items-center">
            <h5 className="m-0">Gerenciamento de planos</h5>
            <span className="block mt-2 md:mt-0 p-input-icon-left">
                <i className="pi pi-search" />
                <InputText type="search" onInput={(e) => setGlobalFilter(e.target.value)} placeholder="Search..." />
            </span>
        </div>
    );

    const hideDeleteProductDialog = () => {
        setDeleteProductDialog(false);
    };

    const deleteProductDialogFooter = (rowData) => {
        return (
            <>
                <Button label="No" icon="pi pi-times" className="p-button-text" onClick={hideDeleteProductDialog} />
                <Button label="Yes" icon="pi pi-check" className="p-button-text" onClick={() => { DeletePlans(rowData?.visible?.id); hideDeleteProductDialog() }} />
            </>
        )
    };




    return (
        <div className="grid crud-demo">
            <div className="col-12">
                <div className="card">
                    <Toast ref={toast} />
                    <Toolbar className="mb-4" left={leftToolbarTemplate}></Toolbar>

                    <DataTable
                        ref={dt}
                        value={plans}
                        // selection={selectedProducts}
                        // onSelectionChange={(e) => setSelectedProducts(e.value)}
                        dataKey="id"
                        paginator
                        rows={10}
                        rowsPerPageOptions={[5, 10, 25]}
                        className="datatable-responsive"
                        paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                        currentPageReportTemplate="Showing {first} to {last} of {totalRecords} products"
                        globalFilter={globalFilter}
                        emptyMessage="No products found."
                        header={header}
                        responsiveLayout="scroll"
                    >
                        {/* <Column selectionMode="multiple" headerStyle={{ width: '3rem' }}></Column> */}
                        <Column align={'center'} field="id" header="Code" sortable body={codeBodyTemplate} headerStyle={{ width: '14%', minWidth: '10rem' }}></Column>
                        <Column align={'center'} field="name" header="Name" sortable body={nameBodyTemplate} headerStyle={{ width: '14%', minWidth: '10rem' }}></Column>
                        <Column align={'center'} field="numberZones" header="Nº de zonas" sortable body={zonasBodyTemplate} headerStyle={{ width: '14%', minWidth: '10rem' }}></Column>
                        <Column align={'center'} field="maxSize" header="Tamanho máximo " sortable body={maxSizeBodyTemplate} headerStyle={{ width: '14%', minWidth: '10rem' }}></Column>
                        <Column align={'center'} field="price" header="Preço" sortable body={priceBodyTemplate} headerStyle={{ width: '14%', minWidth: '10rem' }}></Column>
                        <Column align={'center'} body={actionBodyTemplate}></Column>
                    </DataTable>

                    <ModalEditPlan plan={plan} planDialog={planDialog} hideDialog={() => setPlanDialog(false)} />
                    <ModalCreatePlan planDialog={createDialog} hideDialog={() => setCreateDialog(false)} />
                    {/* <ModalCreateUser createDialog={createDialog} hideDialog={() => setCreateDialog(false)}/> */}
                    <Dialog visible={deleteProductDialog} style={{ width: '450px' }} header="Confirm" modal footer={deleteProductDialogFooter} onHide={hideDeleteProductDialog}>
                        <div className="flex align-items-center justify-content-center">
                            <i className="pi pi-exclamation-triangle mr-3" style={{ fontSize: '2rem' }} />

                            <span>
                                Are you sure you want to delete <b>{deleteProductDialog.name}</b>
                            </span>

                        </div>
                    </Dialog>

                    {/* <Dialog visible={deleteProductsDialog} style={{ width: '450px' }} header="Confirm" modal footer={deleteProductsDialogFooter} onHide={hideDeleteProductsDialog}>
                        <div className="flex align-items-center justify-content-center">
                            <i className="pi pi-exclamation-triangle mr-3" style={{ fontSize: '2rem' }} />
                            {product && <span>Are you sure you want to delete the selected products?</span>}
                        </div>
                    </Dialog> */}
                </div>
            </div>
        </div>
    );
};

export default PlansScreens;
