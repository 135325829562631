import React from "react";
import { isAuthenticated } from "../localStorage/localStorage";


const PrivateRoute = ({ Component }) => {
    const auth = isAuthenticated();

    return auth ? (
        <div>
            {Component}
        </div>
    ) : (
        window.location.href = "https://dev.ludz.one/login");
};

export default PrivateRoute;