import { Form, Formik } from "formik";
import { AutoComplete } from "primereact/autocomplete";
import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";
import { InputText } from "primereact/inputtext";
import { classNames } from "primereact/utils";
import { useState } from "react";
import * as Yup from 'yup';
import { ZonesState } from "../Context/state";


const ModalCreateZones = ({ createDialog, hideDialog }) => {
    const [autoFilteredValue, setAutoFilteredValue] = useState([]);


    const { users } = ZonesState()

    const SignupSchema = Yup.object().shape({
        name: Yup.string()
            .required('Campo Obrigatório'),
        username: Yup.string()
            .min(2, 'Muito curto!')
            .max(50, 'Muito Longo!')
            .required('Campo Obrigatório'),
        email: Yup.string().email('Invalid email').required('Campo Obrigatório'),
        password: Yup.string().required('Campo Obrigatório'),
        passwordConfirm: Yup.string().label('Confirmar senha').required("Campo Obrigatório").oneOf([Yup.ref('password')], 'Senhas diferentes'),
    });

    const initialValues = {
        name: "",
        owner: "",
    }

    const searchUsers = (event) => {
        setTimeout(() => {
            if (!event.query.trim().length) {
                setAutoFilteredValue([...users]);
            } else {
                setAutoFilteredValue(
                    users.filter((props) => {
                        return props.username.toLowerCase().startsWith(event.query.toLowerCase()) || props.email.toLowerCase().startsWith(event.query.toLowerCase());
                    })
                );
            }
        }, 250);
    };

    return (
        <Dialog visible={createDialog} style={{ width: '450px' }} header="Criar Zona" modal className="p-fluid" onHide={hideDialog}>

            <Formik validationSchema={SignupSchema} initialValues={initialValues} onSubmit={(values) => {
                // CreateUser(values)
            }}>
                {({ values, handleChange, errors, touched, handleSubmit }) => {
                    return (
                        <Form onSubmit={handleSubmit}>
                            <div className="field">
                                <label htmlFor="name">Name</label>
                                <InputText name="name" id="name" value={values?.name} onChange={handleChange} required autoFocus className={classNames({ 'p-invalid': errors.name && touched.name })} />
                                {errors.name && touched.name && <small className="p-invalid">{errors.name}</small>}
                            </div>
                            <div className="field">
                                <label htmlFor="name">Escolha o dono da zona</label>
                                <AutoComplete placeholder="Pesquisar usuário" name="owner" id="dd" dropdown multiple value={values.owner} onChange={handleChange} suggestions={autoFilteredValue} completeMethod={searchUsers} field={(values) => {return values.username + " - " + values.email}} />
                                {errors.owner && touched.owner && <small className="p-invalid">{errors.owner}</small>}

                            </div>
                            <div className="flex align-items-center justify-content-end">
                                <div className=" w-6 flex align-items-center justify-content-end">
                                    <Button label="Cancel" type="button" icon="pi pi-times" className="p-button-text" onClick={hideDialog} />
                                    <Button label="Create" type="submit" icon="pi pi-check" className="p-button-text" />
                                </div>
                            </div>

                        </Form>
                    )
                }}
            </Formik>


        </Dialog>
    )
}

export default ModalCreateZones